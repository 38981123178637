import React, { useEffect, useState } from 'react';
import './styles.css';

const DeckBuilder = () => {
  const [cards, setCards] = useState([]); // Store all available cards
  const [deck, setDeck] = useState([]); // Store the currently selected deck
  const [stats, setStats] = useState(null); // Store deck statistics
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(null); // For error handling
  const [elixirClass, setElixirClass] = useState('balanced'); // Store selected elixir class
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering cards
  const [filteredCards, setFilteredCards] = useState([]); // Store filtered cards based on search term
  const [selectedCard, setSelectedCard] = useState(null); // Store the card selected from search

  // Fetch cards when the component mounts
  useEffect(() => {
    fetchCards();
  }, []);

  // Fetch all available cards from API
  const fetchCards = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://test2.webys.org/api/cards');
      if (!response.ok) {
        throw new Error(`Failed to fetch cards: ${response.statusText}`);
      }
      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Invalid data format received from server');
      }
      setCards(data); // Set cards fetched from API
    } catch (error) {
      setError(`Error fetching cards: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Automatically update stats when the deck changes
  useEffect(() => {
    if (deck.length > 0) {
      calculateDeckStats(deck);
    } else {
      setStats(null); // Clear stats if no deck
    }
  }, [deck]);

  // Calculate deck stats by sending a POST request with the current deck
  const calculateDeckStats = async (currentDeck) => {
    try {
      const response = await fetch('https://test2.webys.org/api/deck/stats', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deck: currentDeck }),
      });
      const data = await response.json();
      setStats(data); // Set stats returned from API
    } catch (error) {
      console.error('Error calculating deck stats:', error);
    }
  };

  // Generate a deck based on the selected elixir class
  const autoGenerateDeck = async () => {
    try {
      let url = `https://test2.webys.org/api/generate_deck?elixirClass=${elixirClass}`;

      // Check if a specific card is selected through search
      let deckData = [];
      if (selectedCard) {
        // Include the selected card in the deck
        deckData = [selectedCard];
      }

      const response = await fetch(url);
      const data = await response.json();

      // Merge the selected card with the generated deck, ensuring the total deck size is still 8 cards
      const mergedDeck = [...deckData, ...data.deck].slice(0, 8);
      setDeck(mergedDeck);
      calculateDeckStats(mergedDeck); // Calculate stats for the deck
    } catch (error) {
      console.error('Error generating deck:', error);
    }
  };

  // Add a card to the deck, limited to 8 cards
  const addCardToDeck = (card) => {
    if (deck.length < 8) {
      const updatedDeck = [...deck, card];
      setDeck(updatedDeck);
    } else {
      alert('Deck can only contain 8 cards.');
    }
  };

  // Remove a card from the deck
  const removeCardFromDeck = (index) => {
    const updatedDeck = [...deck];
    updatedDeck.splice(index, 1);
    setDeck(updatedDeck);
  };

  // Copy the current deck to Clash Royale
  const copyDeckToClashRoyale = () => {
    if (!deck || deck.length === 0) {
      alert("Please select a valid deck.");
      return;
    }

    const deckCardIds = deck.map((card) => card.id);
    if (deckCardIds.length !== 8) {
      alert("Please select exactly 8 cards for the deck.");
      return;
    }

    const deckIdsString = deckCardIds.join(';');
    const slots = "0;0;0;0;0;0;0;0";
    const tt = "159000000"; // Arbitrary tt value
    const league = "Royals"; // Arbitrary league value

    const deckLink = `https://link.clashroyale.com/en/?clashroyale://copyDeck?deck=${deckIdsString}&slots=${slots}&tt=${tt}&l=${league}`;
    window.open(deckLink, '_blank');
  };

  // Search for cards by name
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setFilteredCards([]); // Clear filtered cards when search is empty
    } else {
      const filtered = cards.filter(card => card.name.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilteredCards(filtered); // Set filtered cards
    }
  };

  // Handle selecting a card from the search results
  const handleSelectCard = (card) => {
    setSelectedCard(card); // Set the selected card from the search results
    setSearchTerm(''); // Clear search input after selection
    setFilteredCards([]); // Clear search results after selection
  };

  return (
<div className="deck-builder-container">
  <h1>Clash Royale Deck Builder</h1>

  <div className="select-container">
    {/* Elixir Class Selection */}
    <label htmlFor="elixirClass">Select Elixir Class:</label>
    <select
      id="elixirClass"
      value={elixirClass}
      onChange={(e) => setElixirClass(e.target.value)}
    >
      <option value="low">Low Elixir Deck (0-3.5)</option>
      <option value="high">High Elixir Deck (3.5-7)</option>
      <option value="balanced">Balanced Elixir Deck</option>
    </select>

    {/* Search Input */}
    <input
      type="text"
      value={searchTerm}
      onChange={handleSearchChange}
      placeholder="Search, Select and generate"
    />

    {/* Generate Deck Button */}
    <button onClick={autoGenerateDeck}>Generate Deck</button>
  </div>

  {/* Search Result and Selected Card */}
  {filteredCards.length > 0 && (
    <ul>
      {filteredCards.map((card) => (
        <li key={card.id} onClick={() => handleSelectCard(card)}>
          {card.name}
        </li>
      ))}
    </ul>
  )}

  {selectedCard && (
    <div className="selected-card">
      Selected Card: {selectedCard.name}
    </div>
  )}


<div className="deck-section">
        <h2>Your Deck</h2>
        <div id="deck-container" className="deck-grid">
          {deck.length > 0 ? (
            deck.map((card, index) => (
              <div key={card.id} className="card">
                <span className="level">Level {card.level}</span>
                <h4>{card.name}</h4>
                <img 
                  src={card.iconUrls.evolutionMedium ? card.iconUrls.evolutionMedium : card.iconUrls.medium} 
                  alt={card.name} 
                />
                <button onClick={() => removeCardFromDeck(index)}>Remove</button>
              </div>
            ))
          ) : (
            Array.from({ length: 8 }).map((_, i) => (
              <div key={i} className="card empty">
                <h4>No Card</h4>
                <p>Empty Slot</p>
              </div>
            ))
          )}
        </div>

        <div className="copy-button">
          <button onClick={copyDeckToClashRoyale}>Copy Deck</button>
        </div>
      

        {stats && (
          <div id="deck-stats">
            <strong>Average Elixir Cost:</strong> {stats.average_elixir} <br />
            <strong>Elixir Class:</strong> {stats.elixir_class} <br />
            <strong>Win Rate:</strong> {stats.win_rate} <br />
            <strong>Tips:</strong>
            <ul>
              {stats.tips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </ul>
          </div>
        )}

        
      </div>

      <h2>Available Cards</h2>
      <div id="cards-container" className="cards-grid">
        {cards.map((card) => (
          <div key={card.id} className="card">
            <span className="level">Level {card.level}</span>
            <h3>{card.name}</h3>
            <p>Elixir Cost: {card.elixirCost}</p>
            <img 
              src={card.iconUrls.evolutionMedium ? card.iconUrls.evolutionMedium : card.iconUrls.medium} 
              alt={card.name} 
            />
            <button onClick={() => addCardToDeck(card)}>Add to Deck</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeckBuilder;
